import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import stationRender from './assets/station-render-cut.png';
import stationText1 from './assets/station-text-1.png'
import stationText2 from './assets/station-text-2.png'
import stationText3 from './assets/station-text-3.png'

import stationText1Pl from './assets/station-text-1-pl.png'
import stationText2Pl from './assets/station-text-2-pl.png'
import stationText3Pl from './assets/station-text-3-pl.png'



import { useTranslation } from 'react-i18next'

const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 }
};

const FloatingText = ({ asset, alt, top, left, topsm, topmd, leftsm }) => {
    const control = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);
  
    return (
      <Container sx={{
        position: 'absolute',
        mt: {
          xl: top,
          lg: top,
          md: topmd,
          sm: topsm,
          xs: topsm
        },
        ml: {
          xl: left,
          lg: left,
          md: leftsm,
          sm: leftsm,
          xs: leftsm
        },
        maxWidth: {
            xl: '10vw',
            lg: '10vw',
            md: '10vw',
            sm: '15vh',
            xs: '15vh'
        }, 
        '@media (orientation: landscape)': { maxWidth: {
          xl: '10vw',
          lg: '10vw',
          md: '15vw',
          sm: '20vw',
          xs: '20vw'
        }}}} >
        <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}>
            
          <img style={{ height:'100%', width:'100%' }} src={asset} alt={alt} />
        </motion.div>
      </Container>
    );
  };

export default function BasePage() {
  const { t, i18n } = useTranslation();

    return (
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.primary
              : theme.palette.primary, 
          }}
        >
            <Container sx={{ mt: 12, mb: 2, 
            '@media (orientation: portrait)': { mt: 5 },
             maxWidth: {
                xl: 'md',
                lg: 'md',
                md: "md",
                sm: "sm",
                xs: "xs"
            }, }} >

                <Typography variant="h1" component="h1" gutterBottom align="left"
                sx={{
                    maxWidth: {
                        xl: 'md',
                        lg: 'md',
                        md: "md",
                        sm: "sm",
                        xs: "xs"
                    },
                    fontSize: {
                    xl: 100,
                    lg: 100,
                    md: 70,
                    sm: 60,
                    xs: 50
                    }
                }}>
                  { t('base-title-text')}

                </Typography>

                <Typography variant="h4" align="left" sx={{
                    fontSize: {
                        xl: 35,
                        lg: 35,
                        md: 35,
                        sm: 20,
                        xs: 20
                    }
                }}>
                  {t('base-main-text')}     
                </Typography>
            </Container>

            <Container sx={{ mt: 12, mb: 12, position: 'relative',
             maxWidth: {
                xl: 'lg',
                lg: 'md',
                md: "md",
                sm: "sm",
                xs: "xs"
            }, }} >
              <FloatingText asset={i18n.language === 'en' ? stationText2 : stationText2Pl} alt={t('base-image-alt-text2')} top={'-5%'} left={'-16%'} topmd={'-5%'} topsm={'-15%'} leftsm={'0%'}/> 
              <FloatingText asset={i18n.language === 'en' ? stationText3 : stationText3Pl} alt={t('base-image-alt-text3')} top={'3%'} left={'70%'} topmd={'0%'} topsm={'-6%'} leftsm={'55%'}/> 
              <FloatingText asset={i18n.language === 'en' ? stationText1 : stationText1Pl} alt={t('base-image-alt-text1')} top={'35%'} left={'-20%'} topmd={'58%'} topsm={'55%'} leftsm={'45%'}/> 
              <img style={{ height:'100%', width:'100%' }} src={stationRender} alt={t('base-image-alt')} />
            </Container>

        </Box>

        </div>


    );
}