import * as React from 'react';
import './MainPage.scss'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// eslint-disable-next-line
//import HeaderVideo from './assets/hexited-website-short.mp4'
import HeaderVideoMobile from './assets/hexited-website-mobile.mp4'

import EuLogo from './assets/eu-logo-solo.png'
import HexLogo from './assets/hexited-logo.png'
import PlFlag from './assets/pol.png'
import UkFlag from './assets/gbr.png'

import { useTranslation } from 'react-i18next'


var lang = 'en';

export default function MainPage() {

    const { t, i18n } = useTranslation();

    const FloatingText = ({ text, top, left, topsm, topmd, leftsm }) => {     
        return (
          <Container sx={{
            marginTop: '-20%',
            marginBottom: 'auto',
            position: 'absolute',
            ml: {
              xl: left,
              lg: left,
              md: leftsm,
              sm: leftsm,
              xs: leftsm
            },
            maxWidth: {
                xl: '50vw',
                lg: '50vw',
                md: '10vw',
                sm: '10vw',
                xs: '10vw'
            }, 
            '@media (orientation: landscape)': { 
                maxWidth: {
                    xl: '80vh',
                    lg: '80vh',
                    md: '80vh',
                    sm: '20vh',
                    xs: '20vh'
                },   
                marginTop: 'auto',
                marginBottom: 'auto',
            }
            }} >

                <Typography 
                    variant="h1" 
                    component="h1" 
                    gutterBottom align="left"
                    sx={{
                        color: '#fafafa',
                        maxWidth: {
                            xl: 'md',
                            lg: 'md',
                            md: "md",
                            sm: "sm",
                            xs: "xs"
                        },
                        fontSize: {
                        xl: '8vh',
                        lg: '8vh',
                        md: '8vh',
                        sm: '8vh',
                        xs: '8vh'
                        }, 
                        '@media (orientation: portrait)': { 
                            fontSize: {
                                xl: '10vw',
                                lg: '10vw',
                                md: '10vw',
                                sm: '8vw',
                                xs: '8vw'
                                }, }

                    }}>

                    {text}

                </Typography>
          </Container>
        );
    };


    return (
        <Box
            sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.primary
                    : theme.palette.primary 
            }}>

            <div 
                style={{
                    minWidth: '100%',
                    minHeight: '100vh',
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden',
                    WebkitPerspective: 1000,
                    WebkitBackfaceVisibility: 'hidden',
                    transform: 'margin(calc(((100% - 100vw) / 2)))' 
                }}>

                <video 
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        minWidth: '100%',
                        minHeight: '100%',
                        WebkitPerspective: 1000,
                        WebkitBackfaceVisibility: 'hidden',
                        transform: 'translateX(calc(((100% - 100vw) / 2)))' ,
                        overflow: 'clip',
                        objectFit: 'cover',
                        filter: 'blur(0px)' 
                    }} 
                    className='videoTag' 
                    autoPlay 
                    loop 
                    muted 
                    playsInline> 

                        <source src={HeaderVideoMobile} type='video/mp4' alt={t('main-video-alt')}/>
                    
                </video>
            </div>

            <Container 
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,   
                    '@media (orientation: landscape)': { width: '2vw', height: '2vw', mt: '2vw', ml: '1vw' },  
                    '@media (orientation: portrait)': { width: '2vh', height: '2vh', mt: '2vh', ml: '0vh' },
                }}>

                <img style= {{ height:'100%', width:'100%', position: 'absolute', objectFit: 'contain' }} src={lang === 'en' ? PlFlag : UkFlag} alt={t('main-lang-flag-alt')} onClick={()=> { 
                    if(lang === 'en') {
                        i18n.changeLanguage('pl');
                        lang = 'pl';
                    } else {
                        i18n.changeLanguage('en');
                        lang = 'en';
                    }
                }}/>
            </Container>

            <Container 
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,   
                    '@media (orientation: landscape)': { width: '4vw', height: '4vw', mt: '1vw', mr: '4vw' },  
                    '@media (orientation: portrait)': { width: '4vh', height: '4vh', mt: '1vh', mr: '4vh' },
                }}>

                <img style= {{ height:'100%', width:'100%', position: 'absolute', objectFit: 'contain' }} src={EuLogo} alt={t('main-eu-alt')} />
            </Container>

            <Container 
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    '@media (orientation: landscape)': { width: '12vw', height: '12vw', maxWidth: '200px', maxHeight: '200px', mt: '1vw' },  
                    '@media (orientation: portrait)': { width: '12vh', height: '12vh', mt: '4vh' },
                }}>

                <img style= {{ height:'100%', width:'100%', objectFit: 'contain' }} src={HexLogo} alt={t('main-hex-alt')} />
            </Container>
            
            <FloatingText text={t('main-title-text')} top={'20%'} left={'5%'} topmd={'20%'} topsm={'40%'} leftsm={'5%'}/> 

            <Container
                style={{
                    position: 'absolute',
                    width: '30px',
                    height: '60px',
                    margin: '2vh auto',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)'      
                }}>

                <div className="mouse"/>
            </Container>
        </Box>
    );
}