import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';
import euFooter from './assets/eu-footer.jpg';

import { useTranslation } from 'react-i18next'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://hexited.com/">
        hexited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const BodyText = ({ text }) => {
  return (
    <Typography variant="h4" align="justify" sx={{ mt:1, mb:0, 
      maxWidth: {
        xl: 'md',
        lg: 'md',
        md: "md",
        sm: "sm",
        xs: "xs"
    },
                    fontSize: {
                        xl: 20,
                        lg: 20,
                        md: 20,
                        sm: 10,
                        xs: 10
                    }
                }}>
        {text}
               

        </Typography>
  );
}

const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

export default function StickyFooter() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2, 
      '@media (orientation: landscape)': { mb: 20 }}} maxWidth="sm">
      <Typography variant="h1" component="h1" gutterBottom align="center"
                sx={{
                    maxWidth: {
                        xl: 'md',
                        lg: 'md',
                        md: "md",
                        sm: "sm",
                        xs: "xs"
                    },
                    fontSize: {
                      xl: 100,
                      lg: 100,
                      md: 70,
                      sm: 60,
                      xs: 50
                    }
                }}>
                  { t('contact-title-text')}
          
        </Typography>
        <Typography variant="h4" align="center" sx={{
                    fontSize: {
                        xl: 35,
                        lg: 35,
                        md: 35,
                        sm: 20,
                        xs: 20
                    }
                }} >
                  { t('contact-title-subtext')}
          
        </Typography>
        <Typography variant="h4" align="center" sx={{ mt:2, mb:8, 
                    fontSize: {
                        xl: 30,
                        lg: 30,
                        md: 30,
                        sm: 15,
                        xs: 15
                    }
                }}>
                  hello@hexited.com
        </Typography>
        <Button sx={{ borderRadius: 16, height: 50, width: 160, backgroundColor: '#6E7F91', '&:hover': { backgroundColor: '#394C60' }, }} variant="contained" endIcon={<MailIcon />} onClick={() => {
          window.location = 'mailto:hello@hexited.com?subject=Website Inquiry'
        }} alt={t('mail-button-alt')}>
          Email
        </Button>
        
      </Container>
      <Container style={{ 'margin-top': 'auto' }}
      
      sx={{ display: 'flex',
      flexDirection: 'column',
                    maxWidth: {
                        xl: 'md',
                        lg: 'md',
                        md: "md",
                        sm: "sm",
                        xs: "xs"
                    }, }}>


                      {/* <Container sx= {{
                          '@media (orientation: landscape)': {  maxWidth: {
                            xl: 'md',
                            lg: 'md',
                            md: "md",
                            sm: "sm",
                            xs: "xs"
                        }},
                          '@media (orientation: portrait)': { width: '10vw'},

                      }}
                      style ={{'margin-top': 'auto'}}> */}
      <img style={{  
      width: '100%',
      'object-fit': 'cover',
      overflow: 'hidden',
/*       height: 'auto', width: '50vh', */
      
      }} 
      

      src={euFooter} alt={t('eu-logos-alt')} />


{/* </Container> */}

      <BodyText text={"Inteligentny, wielosensorowy system ochrony obszarów rolniczych minimalizujący straty spowodowane działalnością ptactwa."}/>
      <BodyText text={"Celem projektu jest zmniejszenie strat w plonach spowodowanych szkodliwą działalnością ptactwa na terenach uprawnych, " +
       "wytworzenie oprogramowania wraz z elementami wykonawczymi niezbędnymi do realizacji kluczowych funkcjonalności. " + 
       "Efektem będzie wdrożenie na rynek inteligentnego, wielosensorowego system ochrony obszarów rolniczych minimalizującego straty spowodowane działalnością szkodników oraz instalacja " +
       "systemów ochrony obszarów rolniczych."}/>
      <BodyText text={"Wartość projektu: 1 255 295.00 PLN"}/>
      <BodyText text={"Wkład Funduszy Europejskich: 958 435.00 PLN"}/>
        </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 5,
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
            <IconButton aria-label="fb" size="large" alt={t('fb-button-alt')} 
                onClick={() => {
                    openInNewTab('https://facebook.com/hexited');
                }}>
                
                <FacebookIcon/>
            </IconButton>
            <Copyright/>
        </Container>
      </Box>
    </Box>
  );
}


