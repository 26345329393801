import React from 'react';
import './i18n'
import './App.css';
import StickyFooter from './StickyFooter';
import DronePage from './DronePage';
import BasePage from './BasePage';
import MainPage from './MainPage';

function App() {
  return (
    <div className="App">
        <MainPage/>
        <BasePage/>
        <DronePage/>
        <footer>
          <StickyFooter/>
        </footer>
    </div>
  );
}



export default App;
